<template>
	<div class="content">
		<div class="secondWeixin" v-if="secondWeixin"></div>
		<div class="downloadItem">
			<a :href="androidUrl">
				<img class="androidIcon" src="../../assets/img/download/android-but.png" alt="android">
			</a>
			<div @click="openClick">
				<img class="iosIcon" src="../../assets/img/download/googlePlay-but.png" >
			</div>
			<!-- <div @click="openClick">
				<img class="iosIcon" src="../../assets/img/download/ios-but.png" alt="ios">
			</div> -->
			<!-- <a href="https://play.google.com/store/apps/details?id=io.unilive">
				<img class="iosIcon" src="../../assets/img/download/googlePlay-but.png" alt="android">
			</a> -->
			<a href="https://apps.apple.com/us/app/unilive/id6737513557">
				<img class="iosIcon" src="../../assets/img/download/ios-but.png" alt="ios">
			</a>
			<a href="https://testflight.apple.com/join/qdbMkpgY">
				<img class="iosIcon" src="../../assets/img/download/iosTest-but.png" alt="ios">
			</a>
		</div>
	</div>
</template>

<script>
import { getAppVersion } from "../../assets/api/downloadApi.js";
import { Toast } from 'mint-ui';
import { encrypt, decrypt } from "@/assets/js/utils";
export default {
	data() {
		return {
			secondWeixin: false,
			androidUrl: '',
			iosUrl: '',
			showSucceed: false,
		}
	},
	created() {
		this.getAppVersion();
	},
	mounted() {
		//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
		var ua = window.navigator.userAgent.toLowerCase();
		/* alert(JSON.stringify(ua)) */
		//通过正则表达式匹配ua中是否含有MicroMessenger字符串
		if (ua.match(/MicroMessenger/i) == 'micromessenger') {
			this.secondWeixin = true;
			return true;
		} else {
			this.secondWeixin = false;
			return false;
		}
	},
	methods: {
		// 获取下载地址接口
		getAppVersion() {
			getAppVersion({
				type: 0
			}).then((res) => {
				if (res.code == 0) {
					this.androidUrl = res.data.url
				} else {
					this.Toast(res.message)
				}
			})
		},
		openClick(){
            Toast(this.$t('lang.loginToast10'));
		},

		// ios下载跳转
		clickIos() {

		}
	},
}
</script>

<style lang="scss" scoped>
.secondWeixin {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	background-image: url('../../assets/img/download/secondWeixin.png');
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	z-index: 999;
}

.content {
	width: 100%;
	height: 100%;
	background: url('../../assets/img/download/download-bg.png') no-repeat center center;
	background-size: cover;
}

.logoDiv {
	margin: 0 auto;
	padding-top: 2rem;

	.logo {
		display: block;
		width: 2.32rem;
		height: 2.32rem;
		margin: 0 auto;
	}

	.logoTitle {
		display: block;
		width: 1.91rem;
		height: 0.43rem;
		margin: 0.32rem auto;
	}

	.downloadText {
		font-size: 0.36rem;
		font-weight: 400;
		color: #111118;
		text-align: center;
	}
}

.downloadItem {
	margin: 0 auto;
	padding-top: 8.5rem;
	padding-bottom: 1rem;

	img {
		display: block;
		width: 5.8rem;
		height: 1rem;
		margin: 0.4rem auto;
	}
}

.openApp {
	width: 100%;
	position: absolute;
	bottom: 0;
	height: 1.4rem;
	background-color: #FFFFFF;

	.openAppDiv {
		width: 90%;
		height: 1.4rem;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.openLeft {
			width: 70%;
			display: flex;
			align-items: center;

			.logo-icon {
				display: block;
				width: 0.88rem;
				height: 0.88rem;
			}

			div {
				margin-left: 0.22rem;

				img {
					display: block;
					width: 1.1rem;
					height: 0.25rem;
					margin-top: 0.2rem;
				}

				p {
					font-size: 0.24rem;
					line-height: 0.58rem;
					font-weight: 400;
					color: #7C7C92;
				}
			}
		}

		.openButton {
			display: block;
			width: 1.76rem;
			height: 0.88rem;
			background-color: #E716A8;
			border-radius: 0.24rem;
			font-size: 0.26rem !important;
			color: #FFFFFF;
		}
	}
}
</style>